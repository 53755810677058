import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Socials from "../lists/Socials";
import logo from "../../assets/img/logo.svg";
import useBoolean from "../../hooks/useBoolean";
import TermsModal from "../UI/modal/TermsModal";
import SlowAppearAnimation from "../UI/animations/SlowAppearAnimation";
import PageTransition from "../UI/animations/PageTransitions";

function Footer(props) {
  const modalIsVisible = useBoolean(false);
  const openTermsHandler = () => {
    modalIsVisible.setTrue();
  };

  const closeModalHandler = () => {
    setTimeout(modalIsVisible.setFalse, 100);
    // modalIsVisible.setFalse();
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        {/* <Socials extended={true} addClass="footer__socials" /> */}
        <div className="footer__list">
          <ul>
            <li>
              <p>Copyright © {new Date().getFullYear()}</p>
            </li>
            <li>
              <a href="#!" onClick={openTermsHandler}>
                Terms of use
              </a>
            </li>
            <li>
              <a href="#!" onClick={openTermsHandler}>
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SlowAppearAnimation boolean={modalIsVisible.boolean}>
        {
          // modalIsVisible.boolean &&
          ReactDOM.createPortal(
            <TermsModal
              onClose={closeModalHandler}
              isVisible={modalIsVisible.boolean}
            />,
            document.getElementById("root")
          )
        }
      </SlowAppearAnimation>
    </footer>
  );
}

export default Footer;
